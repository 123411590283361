<template>
    <div
        v-if="text"
        class="article"
    >
        <div v-html="text" /> <!-- eslint-disable-line vue/no-v-html -->
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
            default: ''
        }
    }
};
</script>
